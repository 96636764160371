function checkVowel(term) {
  const vowels = ["a", "e", "i", "o", "u", "y"]
  return vowels.includes(term.charAt(0)) ? true : false
}

export const formatSubject = (base, value) => {
  switch (process.env.GATSBY_LANGUAGE) {
    case "French":
      if (base === "je" && checkVowel(value)) {
        return "j'"
      }
      if (base === "il") {
        return "il, elle"
      }
      if (base === "ils") {
        return "ils, elles"
      }
      return base
    default:
      return base
  }
}

/** @jsx jsx */
import { jsx } from "theme-ui"
import { Fragment } from "react"
import styled from "@emotion/styled"
import _ from "lodash"
import { ContentWrapper, Main, Sidebar } from "gatsby-theme-elements"

import Search from "./Search"
import VerbTable from "./VerbTable"
import AdBox from "../Ad"
import SEO from "../Seo"

export default ({ pageContext }) => {
  const title = _.capitalize(pageContext.verbData.verb)

  return (
    <Fragment>
      <SEO title={`${title} Conjugation`} />
      <ContentWrapper layout="content-sidebar" sx={{ p: [0, "60px 20px"] }}>
        <Main>
          <VerbNav id="verb-nav" sx={{ p: [20, 0] }}>
            <Search post />
          </VerbNav>
          <VerbHeading sx={{ margin: ["30px 0 45px", "60px 0 85px"] }}>
            <h1 sx={{ fontSize: ["36px", "48px"] }}>
              {pageContext.verbData.verb}
            </h1>
            <div className="meaning" sx={{ fontFamily: "heading" }}>
              {pageContext.verbData.meaning}
            </div>
          </VerbHeading>
          <VerbTable
            pageTitle={title}
            data={pageContext.verbData.forms}
            verb={pageContext.verbData.verb}
          />
        </Main>
        <Sidebar>
          <AdContainer>
            <AdBox type="small" />
            <AdBox type="large" sticky />
          </AdContainer>
        </Sidebar>
      </ContentWrapper>
    </Fragment>
  )
}

const VerbNav = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const VerbHeading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: fadeIn ease 0.3s forwards 0.2s;

  h1 {
    text-transform: capitalize;
  }

  .meaning {
    font-size: 24px;
    color: #8c8c8c;
  }
`
const AdContainer = styled.div`
  opacity: 0;
  animation: fadeInUp ease 0.5s forwards 0.3s;
`

/** @jsx jsx */
import { jsx } from "theme-ui"

import VerbTense from "./VerbTense"
import { TableWrapper } from "./VerbTable"
import { formatSubject } from "./rowFormatter"

export default ({ data, verb }) => {
  let verbTenses = []

  data.forEach(({ tenses }) =>
    tenses.forEach(tense => {
      return tense.common ? verbTenses.push(tense) : null
    })
  )

  return (
    <TableWrapper className="edit-theme">
      <h2
        className="edit-heading"
        sx={{
          color: "primary",
          bg: "#fbfbfb",
          position: ["sticky", "relative"],
          top: 0,
        }}
      >
        Common Tenses
      </h2>
      <div className="table-section">
        {verbTenses.map((tense, index) => (
          <VerbTense
            key={index}
            verb={verb}
            category="Common"
            tense={tense.commonName ? tense.commonName : tense.id}
          >
            <h4 sx={{ color: "primary" }}>
              {tense.commonName ? tense.commonName : tense.id}
            </h4>
            <table>
              <tbody>
                {Object.entries(tense.conjugation).map(entry =>
                  entry[1] !== null ? (
                    <tr key={entry[0]}>
                      <td>{formatSubject(entry[0], entry[1])}</td>
                      <td>{entry[1]}</td>
                    </tr>
                  ) : null
                )}
              </tbody>
            </table>
          </VerbTense>
        ))}
      </div>
    </TableWrapper>
  )
}

/** @jsx jsx */
import { jsx } from "theme-ui"

import { useEditMode } from "../../context/EditContext"

export default ({ tense, children, verb, category, ...props }) => {
  const { editMode, toggleEditModal } = useEditMode()
  const label = `${verb} - ${tense} ${category ? "(" + category + ") " : ""}`

  return (
    <div
      className="edit-verb"
      sx={{ transition: "all ease .25s" }}
      onClick={() =>
        editMode ? toggleEditModal("edit", "verb", label, "Fix an error") : null
      }
      {...props}
    >
      {children}
    </div>
  )
}

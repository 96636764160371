/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "@emotion/styled"
import Helmet from "react-helmet"

import { useEditMode } from "../../context/EditContext"
import { TrendingIcon, ListIcon, EditIcon } from "../../assets/icons"
import SocialShare from "../SocialShare"
import ToolButton from "../ToolButton"
import ToggleButton from "../ToggleButton"
import language from "../../config/language"

const toggleOptions = [
  { label: "Common", icon: <TrendingIcon />, value: true },
  { label: "View All", icon: <ListIcon />, value: false },
]

export default ({ data, title, common, setCommon }) => {
  const { editMode, toggleEditMode } = useEditMode()

  return (
    <ToolbarWrapper>
      {editMode ? (
        <Helmet>
          <body className="edit-mode" />
        </Helmet>
      ) : null}
      <ToolbarLeft
        sx={{
          p: ["0px 20px 30px", 0],
          alignItems: ["center", "flex-start"],
          flex: [1, "none"],
        }}
      >
        {/* <SocialShare
          title={title}
          postType="Verbs"
          size={50}
          sx={{ mb: 20, p: 0, display: ["none", "flex"] }}
        /> */}
        <ButtonWrapper>
          <ToggleButton
            state={common}
            setState={setCommon}
            options={toggleOptions}
            color={language.color_primary}
          />
          <ToolButton
            id="toggle-edit-mode"
            onClick={() => toggleEditMode()}
            icon={<EditIcon />}
            text={editMode ? "Close Editor" : "Editor"}
          />
        </ButtonWrapper>
      </ToolbarLeft>
      <ToolbarRight sx={{ flex: 1, m: ["0 20px", "15px 0 0 30px"] }}>
        {data.tenses.map(tense => (
          <SingleField className="edit-theme" key={tense.id}>
            <h5
              className="heading edit-theme"
              sx={{
                fontSize: 20,
                color: "primary",
              }}
            >
              {tense.id}
            </h5>
            <div className="value">{tense.term}</div>
          </SingleField>
        ))}
      </ToolbarRight>
    </ToolbarWrapper>
  )
}

const ToolbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  padding-bottom: 20px;
  opacity: 0;
  animation: fadeInUp ease 0.4s forwards 0.1s;
`

const SingleField = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow: hidden;

  .heading {
    flex: 1;
    width: 100%;
    font-weight: 500;
    background: #fbfbfb;
    padding: 12px 25px;
    border-bottom: 1px solid #ccc;
  }

  .value {
    padding: 12px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;

  .toggle-button {
    margin-right: 15px;
  }

  .tool-button svg {
    padding: 14px 12px;
  }
`

const ToolbarLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const ToolbarRight = styled.div`
  display: flex;
  align-items: flex-end;
  border: 1px solid #ccc;
`

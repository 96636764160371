/** @jsx jsx */
import { jsx } from "theme-ui"
import { Fragment } from "react"
import styled from "@emotion/styled"

export default ({ icon, text, marginRight = 0, children, ...props }) => {
  return (
    <ToolButton
      className="tool-button"
      sx={{
        fontFamily: "heading",
        marginRight,
        "&:hover": { color: "primary", svg: { fill: "primary" } },
      }}
      {...props}
    >
      {children ? (
        children
      ) : (
        <Fragment>
          {icon}
          <span>{text}</span>
        </Fragment>
      )}
    </ToolButton>
  )
}

const ToolButton = styled.button`
  padding: 0;
  background: #fbfbfb;
  display: flex;
  align-items: center;
  font-size: 14px;
  letter-spacing: 0.8px;
  color: #6b6b6b;
  border: 1px solid #ccc;
  border-radius: 2px;
  cursor: pointer;
  outline: none;
  transition: all ease 0.2s;

  span {
    padding: 0 15px;
    display: none;
  }

  svg {
    padding: 12px;
    fill: #808080;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 6px 5px 5px -1px rgba(0, 0, 0, 0.03);
  }

  @media screen and (min-width: 780px) {
    span {
      display: flex;
    }
    svg {
      border-right: 1px solid #ccc;
    }
  }
`

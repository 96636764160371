/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState, Fragment } from "react"
import styled from "@emotion/styled"

import VerbToolbar from "./VerbToolbar"
import CommonTable from "./CommonTable"
import VerbTense from "./VerbTense"
import { formatSubject } from "./rowFormatter"

export default ({ data, verb, pageTitle }) => {
  const [common, setCommon] = useState(true)

  return (
    <Fragment>
      <VerbToolbar
        title={pageTitle}
        data={data[0]}
        common={common}
        setCommon={setCommon}
      />
      {common ? (
        <CommonTable data={data} verb={verb} />
      ) : (
        data.map(section =>
          section.category === "Nominal" ? null : (
            <TableWrapper className="edit-theme" key={section.category}>
              <h2
                className="edit-heading"
                sx={{
                  color: "primary",
                  bg: "#fbfbfb",
                  position: ["sticky", "relative"],
                  top: 0,
                }}
              >
                {section.category}
              </h2>
              <div className="table-section">
                {section.tenses.map(tense => (
                  <VerbTense
                    key={tense.id}
                    verb={verb}
                    category={section.category}
                    tense={tense.id}
                  >
                    {section.local !== "Imperative" ? (
                      <h4 sx={{ color: "primary" }}>{tense.id}</h4>
                    ) : null}
                    <table>
                      <tbody>
                        {Object.entries(tense.conjugation).map(entry =>
                          entry[1] !== null ? (
                            <tr key={entry[0]}>
                              <td>{formatSubject(entry[0], entry[1])}</td>
                              <td>{entry[1]}</td>
                            </tr>
                          ) : null
                        )}
                      </tbody>
                    </table>
                  </VerbTense>
                ))}
              </div>
            </TableWrapper>
          )
        )
      )}
    </Fragment>
  )
}

export const TableWrapper = styled.div`
  border: 1px solid gainsboro;
  // overflow: hidden;
  margin: 20px 0 40px;
  opacity: 0;
  animation: fadeInUp ease 0.6s forwards 0.3s;

  @media screen and (max-width: 960px) {
    border-right: none;
    border-left: none;
    border-radius: 0;
  }

  h2 {
    font-size: 25px;
    font-weight: 500;
    border-bottom: 1px solid gainsboro;
    padding: 10px 30px;
  }

  h4 {
    font-weight: 500;
    font-size: 20px;
    border-bottom: 1px solid gainsboro;
    padding-bottom: 8px;
    margin-bottom: 20px;
  }

  tr td:first-of-type {
    font-weight: 700;
    min-width: 100px;
  }

  .table-section {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    grid-gap: 30px;
    padding: 20px 30px;
  }
`

/** @jsx jsx */
import { jsx } from "theme-ui"
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  RedditShareButton,
  RedditIcon,
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share"
import { Location } from "@reach/router"

const defaults = [
  "facebook",
  "twitter",
  "linkedIn",
  "reddit",
  "whatsapp",
  "email",
]

export default ({
  title,
  postType,
  platforms = defaults,
  size = 53,
  ...props
}) => {
  const shareTitle = `${title + " " + postType}| ${
    process.env.GATSBY_LANGUAGE
  } Study Guide`

  const renderIcon = (type, url) => {
    switch (type) {
      case "facebook":
        return (
          <FacebookShareButton url={url}>
            <FacebookIcon size={size} />
          </FacebookShareButton>
        )
      case "twitter":
        return (
          <TwitterShareButton url={url} via="mkdarshay" title={shareTitle}>
            <TwitterIcon size={size} />
          </TwitterShareButton>
        )
      case "linkedIn":
        return (
          <LinkedinShareButton url={url}>
            <LinkedinIcon size={size} />
          </LinkedinShareButton>
        )
      case "reddit":
        return (
          <RedditShareButton url={url} title={shareTitle}>
            <RedditIcon size={size} />
          </RedditShareButton>
        )
      case "whatsapp":
        return (
          <WhatsappShareButton url={url} title={shareTitle}>
            <WhatsappIcon size={size} />
          </WhatsappShareButton>
        )
      case "email":
        return (
          <EmailShareButton url={url} subject={shareTitle}>
            <EmailIcon size={size} />
          </EmailShareButton>
        )
      default:
        return null
    }
  }
  return (
    <Location>
      {({ location }) => (
        <div
          className="social-share grayscale"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            my: 10,
            px: [20, 0],
            "> div": {
              cursor: "pointer",
            },
            ".social-icon--reddit rect": {
              fill: "#f54300",
            },
            ".SocialMediaShareButton": {
              mr: [0, 10],
              borderRadius: [0, 2],
              overflow: "hidden",
              transition: "all ease .25s",
              "&:hover": {
                transform: "translateY(-5px)",
              },
            },
          }}
          {...props}
        >
          {platforms.map(item => (
            <div key={item}>{renderIcon(item, location.href, size)}</div>
          ))}
        </div>
      )}
    </Location>
  )
}
